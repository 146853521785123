import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from "../../components/Loader";
import * as xlsx from 'xlsx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Popover, PopoverTrigger, PopoverContent, Button, Input } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";


const Analytics = () => {

    //Modal of Next UI
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const [profit, setProfit] = useState()
    const [loading, setloading] = useState(true);
    const navigate = useNavigate()

    const { user } = useSelector(state => state.user);
    useEffect(() => {
        if (user.role !== "Admin") {
            navigate("/home");
        }
    })

    const getOverallProfit = async () => {
        try {
            setloading(true);
            const response = await axios.get("/trip/get/overAllProfit");
            setloading(false);
            if (response.data.success) {
                setProfit(response.data.profit);
            }
        } catch (error) {
            setloading(false);
            toast.error(error.response.data.message);
        }
    }

    const [company, setCompany] = useState()
    const [lorry, setLorry] = useState()
    const [lorryBalance, setLorryBalance] = useState()
    const getOverallTotalOf3 = async () => {
        try {
            setloading(true);
            const response = await axios.get("/trip/get/comp-lorry-balance-total");
            setloading(false);
            if (response.data.success) {
                setCompany(response.data.overallCompanyTotal);
                setLorry(response.data.overallLorryTotal);
                setLorryBalance(response.data.overallLorryBalance);
            }

        } catch (error) {
            setloading(false)
            toast.error(error.response.data.message);
        }
    }

    const [expense, setexpense] = useState(0)
    const [incentive, setIncentive] = useState(0);

    const getOverallTotalIncentive = async () => {
        try {
            setloading(true);
            const response = await axios.get("trip/get/totalIncentive");
            setloading(false);
            if (response.data.success) {
                setIncentive(response.data.sumOfIncentive);
            }
        } catch (error) {
            setloading(false);
            toast.error(error.response.data.message);
        }
    }
    const getOverallTotalExpenses = async () => {
        try {
            setloading(true);
            const response = await axios.get("expenses/get/overAllTotalExpenses");
            setloading(false);
            if (response.data.success) {
                setexpense(response.data.totalExpense);
            }
        } catch (error) {
            setloading(false);
            toast.error(error.response.data.message);
        }
    }

    const [companyIncoming, setCompanyIncoming] = useState(0);
    const getCompanyIncoming = async () => {
        try {
            setloading(true);
            const response = await axios.get("/trip/get/overallIncomingCompanyBalance");
            setloading(false);
            if (response.data.success) {
                setCompanyIncoming(response.data.incomingCompanyBalance);
            }
        } catch (error) {
            setloading(false);
            toast.error(error.response.data.message);
        }
    }

    const [extraAmount, setExtraAmount] = useState()
    const getSumOfExtraAmount = async () => {
        try {
            const response = await axios.get("/trip/get/sumOfExtraAmount");

            if (response.data.success) {
                setExtraAmount(response.data.sumOfExtraAmount);
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }
    }


    useEffect(() => {
        getOverallProfit();
        getOverallTotalOf3();
        getOverallTotalExpenses();
        getOverallTotalIncentive();
        getCompanyIncoming();
        getSumOfExtraAmount();
    }, [])

    const [trips, setTrips] = useState();


    const [profitDateWise, setProfitDateWise] = useState(0);
    const [incentiveDateWise, setIncentiveDateWise] = useState(0);
    const [lorryDateWise, setLorryDateWise] = useState(0);
    const [companyDateWise, setCompanyDateWise] = useState(0);
    const [companyIncomingDateWise, setCompanyIncomingDateWise] = useState(0)
    const [lorryBalDateWise, setLorryBalDateWise] = useState(0);
    const [expenseDateWise, setExpenseDateWise] = useState(0);
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const form = new FormData(e.target);
            const data = Object.fromEntries(form.entries());

            if (data.startDate === "" && data.endDate === "") {
                toast.error('required fields');
                return
            }

            const response1 = await axios.get(`trip/get/profitByGivenRange?startDate=${data.startDate}&endDate=${data.endDate}`);

            const response2 = await axios.get(`trip/get/LorryAndCompanyAndBalanceTotalByRange?startDate=${data.startDate}&endDate=${data.endDate}`);

            const response3 = await axios.get(`/expenses/get/OverAllTotalExpensesByRange?startDate=${data.startDate}&endDate=${data.endDate}`);

            const response4 = await axios.get(`/trip/get/incomingCompanyBalanceByRange?startDate=${data.startDate}&endDate= ${data.endDate}`)

            const response5 = await axios.get(`/trip/get/totalIncentiveByDate?startDate=${data.startDate}&endDate=${data.endDate}`);

            if (response1.data.success && response2.data.success && response3.data.success && response4.data.success && response5.data.success) {
                setProfitDateWise(response1.data.profit);
                setLorryDateWise(response2.data.lorryTotal);
                setCompanyDateWise(response2.data.companyTotal);
                setLorryBalDateWise(response2.data.lorryBalance);
                setExpenseDateWise(response3.data.expense);
                setCompanyIncomingDateWise(response4.data.incomingCompanyBalance);
                setIncentiveDateWise(response5.data.sumOfIncentive);
                setShowDownloadIcon(true);
            }

            const res = await axios.post('/trip/get/tripsByDateRange', data);
            if (res.data.success) {
                console.log('data get');

                setTrips(res.data.trips);
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const handleOverAllDownload = () => {
        const financialData = [{ description: "Overall Total of Company Amount", amount: company.toLocaleString('en-IN') }, { description: "Overall Incoming Balance of Company Amount", amount: companyIncoming.toLocaleString('en-IN') }, { description: "Overall Total of Lorry Amount", amount: lorry.toLocaleString('en-IN') }, { description: "Overall Balance of Lorry", amount: lorryBalance.toLocaleString('en-IN') }, { description: "Estimated Profit (sum of ton * 15)", amount: profit.toLocaleString('en-IN') }, { description: "Overall total Incentive", amount: incentive.toLocaleString('en-IN') }, { description: "Overall total expenses", amount: expense.toLocaleString('en-IN') }, { description: "Overall Profit((Estimated Profit + Incentive) - Expenses)", amount: ((profit + incentive - expense).toLocaleString('en-IN')) }];

        console.log(financialData);

        const workSheet = xlsx.utils.json_to_sheet(financialData);
        const workBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
        xlsx.writeFile(workBook, 'OverAllFinancialData.xlsx');

    }

    const handleRangeDownload = () => {
        const financialData = [{ description: "Overall Total of Company Amount", amount: companyDateWise.toLocaleString('en-IN') }, { description: "Overall Incoming Balance of Company Amount", amount: companyIncomingDateWise.toLocaleString('en-IN') }, { description: "Overall Total of Lorry Amount", amount: lorryDateWise.toLocaleString('en-IN') }, { description: "Overall Balance of Lorry", amount: lorryBalDateWise.toLocaleString('en-IN') }, { description: "Estimated Profit (sum of ton * 15)", amount: profitDateWise.toLocaleString('en-IN') }, { description: "Overall total Incentive", amount: incentiveDateWise.toLocaleString('en-IN') }, { description: "Overall total expenses", amount: expenseDateWise.toLocaleString('en-IN') }, { description: "Overall Profit((Estimated Profit + Incentive) - Expenses)", amount: ((profitDateWise + incentiveDateWise) - expenseDateWise).toLocaleString('en-IN') }];

        const workSheet = xlsx.utils.json_to_sheet(financialData);
        const workBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
        xlsx.writeFile(workBook, 'RangedFinancialData.xlsx');

    }

    const handleTripDataDownload = () => {

        let sheet = [];
        trips?.forEach((trip) => {
            const formatDate = (date) => new Date(date).toLocaleDateString('en-GB');
            let date = new Date(trip.tripDate).toLocaleDateString('en-GB') + "-" + new Date(trip.tripDate).toLocaleTimeString('en-GB');
            let obj = {
                tripDate: date,
                gpiNo: trip.gpiNo || 0,
                ton: trip.ton || 0,
                lrNo: trip.lrNo || '',
                truckNo: trip.truckNo || '',
                destination: trip.destination || '',
                threePointAmount: trip.threePointAmount || 0,
                threePointBill: trip.threePointBill || '',
                threePointDate: trip.threePointDate ? formatDate(trip.threePointDate) : '',
                companyRate: trip.companyRate || 0,
                companyTotal: trip.companyTotal || 0,
                lorryRate: trip.lorryRate || 0,
                lorryTotal: trip.lorryTotal || 0,
                advancedAmount: trip.advancedAmount || 0,
                advancedAmountDate: trip.advancedAmountDate ? formatDate(trip.advancedAmountDate) : '',
                dieselAmount: trip.dieselAmount || 0,
                balanceAmount: trip.balanceAmount || 0,
                billNo: trip.billNo || '',
                billDate: trip.billDate ? formatDate(trip.billDate) : '',
                tollBillNo: trip.tollBillNo || '',
                tollAmount: trip.tollAmount || 0,
                tollDate: trip.tollDate ? formatDate(trip.tollDate) : '',
                soldToParty: trip.soldToParty || '',
                contactNo: trip.contactNo || 0,
                postPaidAmount: trip.postPaidAmount || 0,
                postPaidDate: trip.postPaidDate ? formatDate(trip.postPaidDate) : '',
                postPaid2Amount: trip.postPaid2Amount || 0,
                postPaid2Date: trip.postPaid2Date ? formatDate(trip.postPaid2Date) : '',
                postPaid3Amount: trip.postPaid3Amount || 0,
                postPaid3Date: trip.postPaid3Date ? formatDate(trip.postPaid3Date) : '',
                extraAmount: trip.extraAmount || 0,
                extraAmountRemark: trip.extraAmountRemark || '',
                unloading: trip.unloading || 0
            };

            sheet.push(obj);

        });

        const workSheet = xlsx.utils.json_to_sheet(sheet);
        const workBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
        xlsx.writeFile(workBook, 'TripDataByRange.xlsx');
    }
    const [showDownloadIcon, setShowDownloadIcon] = useState(false);

    //submitting threshold amount in db

    const handleThreshold = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        console.log(data);

        try {
            const response = await axios.post('/trip/addThresholdAmount', data);
            if (response.data.success) {
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const [thresholds, setThresholds] = useState([]);
    const getThresholds = async () => {
        try {
            const response = await axios.get("/trip/get/thresholds");
            if (response.data.success) {
                setThresholds(response.data.threshold);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const [lorrySum, setLorrySum] = useState();
    const [companySum, setCompanySum] = useState();
    const [threshold, setThreshold] = useState();
    const calculateUptillDate = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target)
        const data = Object.fromEntries(form.entries());

        console.log(data);


        try {

            const res = await axios.get("/trip/get/thresholds")
            if (res.data.success) {
                setThreshold(res.data.threshold[0]);
            }

            const response = await axios.post("/trip/lorrySumTillDate", data);
            if (response.data.success) {
                setLorrySum(response.data.lorryTotal);
            }

            const response2 = await axios.post("/trip/compantSumTillDate", data);
            if (response2.data.success) {
                setCompanySum(response2.data.companyTotal);
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }
    }


    if (loading) {
        return <Loader />
    }


    return (
        <>
            <div className='m-4 flex justify-center flex-col items-center gap-2'>
                <h2>Overall Calculations <i class=" ml-5 fa-solid fa-file-export text-lg  items-baseline text-orange-300 cursor-pointer" onClick={handleOverAllDownload} title='Download Report'></i></h2>

                <div className='flex items-center justify-center gap-2'>
                    <Popover placement="bottom" showArrow offset={10}>
                        <PopoverTrigger>
                            <Button color="primary" >Set Closing Amounts</Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[400px]">
                            {(titleProps) => (
                                <div className="px-1 py-2 w-full">
                                    <p className="text-small font-bold text-foreground" {...titleProps}>
                                        Add Closing Amounts
                                    </p>
                                    <form onSubmit={handleThreshold} className="mt-2 flex flex-col gap-2 w-full">
                                        <Input type='number' name='companyTotal' label="Company Total" size="sm" variant="bordered" />
                                        <Input type='number' name='lorryTotal' label="Lorry Total" size="sm" variant="bordered" />
                                        <Input type='number' name='lorryBalance' label="Lorry Balance" size="sm" variant="bordered" />
                                        <Input type='number' name='incomingCompanyBalance' label="Incoming Company Balance" size="sm" variant="bordered" />
                                        <Button color='primary' type='submit'>Submit</Button>
                                    </form>
                                </div>
                            )}
                        </PopoverContent>
                    </Popover>

                    <Button onPress={() => {
                        onOpen();
                        getThresholds();
                    }} color='primary' className='text-sm'><i class="fa-solid fa-eye"></i></Button>

                    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                        <ModalContent>
                            {(onClose) => (
                                <>
                                    <ModalHeader className="flex flex-col gap-1">Amounts added list:</ModalHeader>
                                    <ModalBody>
                                        <h2>Company Total: {thresholds[0]?.companyTotal}</h2>
                                        <h2>Lorry Total: {thresholds[0]?.lorryTotal}</h2>
                                        <h2>Lorry Balance: {thresholds[0]?.lorryBalance}</h2>
                                        <h2>Incoming company balance: {thresholds[0]?.incomingCompanyBalance}</h2>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="danger" variant="light" onPress={onClose}>
                                            Close
                                        </Button>
                                        {/* <Button color="primary" onPress={onClose}>
                                            Action
                                        </Button> */}
                                    </ModalFooter>
                                </>
                            )}
                        </ModalContent>
                    </Modal>
                </div>


                <table className="items-center text-blueGray-700 w-[80%] rounded-lg bg-gray-200">

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Overall Total of Company Amount
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {company?.toLocaleString("en-IN")}
                        </td>

                    </tr>
                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Overall Incoming Balance of Company Amount
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {companyIncoming?.toLocaleString("en-IN")}
                        </td>

                    </tr>

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Overall Total of Lorry Amount
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 ">
                            {lorry?.toLocaleString('en-IN')}
                        </td>
                    </tr>

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Overall Balance of Lorry
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {lorryBalance?.toLocaleString("en-IN")}
                        </td>

                    </tr>
                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-base whitespace-nowrap p-4 text-left">
                            {`Estimated Profit  (sum of ton * 15)`}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {profit?.toLocaleString("en-IN")}
                        </td>
                    </tr>

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-base whitespace-nowrap p-4 text-left">
                            {`Overall total Incentive`}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {incentive?.toLocaleString("en-IN")}
                        </td>
                    </tr>

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-base whitespace-nowrap p-4 text-left">
                            {`Overall total expenses`}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {expense?.toLocaleString("en-IN")}
                        </td>
                    </tr>

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-base whitespace-nowrap p-4 text-left">
                            {`Profit ((Estimated Profit + Incentive) - Expense)`}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {((profit + incentive) - expense).toLocaleString("en-IN")}
                        </td>
                    </tr>

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-base whitespace-nowrap p-4 text-left">
                            {`Sum of Extra Amount`}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {extraAmount?.toLocaleString("en-IN")}
                        </td>
                    </tr>

                </table>

                <h2 className='mt-4'>Calcultions based on Date
                    {
                        showDownloadIcon ? <>
                            <i class=" ml-5 fa-solid fa-file-export text-lg  items-baseline text-orange-300 cursor-pointer" onClick={handleRangeDownload} title='Download Report'></i>
                        </> : <></>
                    }
                </h2>
                <div >
                    <form onSubmit={handleSubmit} className='flex items-center gap-5 mt-4 bg-gray-300 p-2 rounded-lg'>
                        <Input type="date" name='startDate' label={"Start date"} className='w-[300px]' />
                        <Input type="date" name='endDate' label={"End date"} className='w-[300px]' />
                        <Button type='submit' color='primary' className='font-bold hover:border hover:border-black'><i class="fa-solid fa-calculator"></i>calculate</Button>
                    </form>

                </div>

                {
                    showDownloadIcon ?
                        <>
                            <div className='flex items-baseline'>
                                <h2>Trip Data</h2>
                                <i class=" ml-5 fa-solid fa-file-export text-lg  items-baseline text-orange-300 cursor-pointer" onClick={handleTripDataDownload} title='Download Trip Data'></i>
                            </div>
                            <div className="flex flex-col w-[25rem] h-[15rem] md:w-[36rem] mb-8 lg:w-[50rem] md:h-[16rem] ">
                                <div className="-m-1.5 overflow-x-auto  bottom-0 inset-x-0">
                                    <div className="p-1.5 min-w-full inline-block align-middle">
                                        <div className="border rounded-lg overflow-hidden  shadow">
                                            <table className="min-w-full divide-y divide-gray-200 ">
                                                <thead className="bg-gray-50 ">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        ></th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            TRIP DATE
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            GPI No.
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            TON
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            LR No
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            Truck No.
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            Destination
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-smm text-gray-500 uppercase"
                                                        >
                                                            COMPANY RATE
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-smm text-gray-500 uppercase"
                                                        >
                                                            COMPANY TOTAL
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-smm text-gray-500 uppercase"
                                                        >
                                                            LORRY RATE
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            LORRY TOTAL
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            ADVANCE AMOUNT
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            ADVANCE AMOUNT DATE
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            DIESEL AMOUNT
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            BALANCE AMOUNT
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            SOLD TO PARTY
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            CONTACT
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            POST PAID 1 DATE
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            POST PAID 1 AMOUNT
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            POST PAID 2 DATE
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            POST PAID 2 AMOUNT
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            POST PAID 3 DATE
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            POST PAID 3 AMOUNT
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            Extra Amount
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            Extra Amount Remark
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            Unloading
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            Bill NO
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            Bill DATE
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            TOLL BILL NO
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            TOLL AMOUNT
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            TOLL BILL DATE
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            3 POINT BILL
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-smm text-gray-500 uppercase"
                                                        >
                                                            3 POINT AMT
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            3 POINT DATE
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                                                        >
                                                            INCENTIVE AMOUNT
                                                        </th>


                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200">
                                                    {trips?.map((t, index) => {
                                                        return (
                                                            <>
                                                                <tr className="hover:bg-gray-300 cursor-pointer">
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {index + 1}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {new Date(t?.tripDate).toLocaleDateString(
                                                                            "en-GB"
                                                                        ) +
                                                                            " - " +
                                                                            new Date(t?.tripDate).toLocaleTimeString()}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.gpiNo}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.ton}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.lrNo}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.truckNo}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.destination}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.companyRate}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.companyTotal}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.lorryRate}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.lorryTotal}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.advancedAmount}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t.advancedAmountDate ? new Date(t?.advancedAmountDate).toLocaleDateString("en-GB") : "-"}
                                                                    </td>
                                                                    <td className=" px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.dieselAmount}
                                                                    </td>
                                                                    <td className={`${(t?.balanceAmount === 0 && t?.lorryRate !== 0) ? 'text-green-600 font-bold bg-green-100' : t?.balanceAmount < 0 ? 'text-red-500 font-bold bg-red-100' : 'text-black'} px-6 py-4 whitespace-nowrap text-sm font-medium`}>
                                                                        {t?.balanceAmount}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.soldToParty}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.contactNo}
                                                                    </td>


                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.postPaidDate ? new Date(t?.postPaidDate)?.toLocaleDateString("en-GB") : "-"}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.postPaidAmount}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.postPaid2Date ? new Date(t?.postPaid2Date)?.toLocaleDateString("en-GB") : "-"}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.postPaid2Amount}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.postPaid3Date ? new Date(t?.postPaid3Date)?.toLocaleDateString("en-GB") : "-"}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.postPaid3Amount}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.extraAmount}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.extraAmountRemark}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.unloading}
                                                                    </td>

                                                                    <td className={t?.billNo ? "text-green-700 bg-green-100 px-6 py-4 whitespace-nowrap text-sm font-medium" : null}>
                                                                        {t?.billNo}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.billDate ? new Date(t?.billDate).toLocaleDateString("en-GB") : "-"}
                                                                    </td>
                                                                    <td className={t?.tollBillNo ? "text-green-700 bg-green-100 px-6 py-4 whitespace-nowrap text-sm font-medium" : null}>
                                                                        {t?.tollBillNo}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.tollAmount}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.tollDate ? new Date(t?.tollDate).toLocaleDateString("en-GB") : "-"}
                                                                    </td>
                                                                    <td className={t?.threePointBill ? "text-green-700 bg-green-100 px-6 py-4 whitespace-nowrap text-sm font-medium" : null}>
                                                                        {t?.threePointBill}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                                                        {t?.threePointAmount}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                                                                        {t?.threePointDate ? new Date(t?.threePointDate).toLocaleDateString("en-GB") : "-"}
                                                                    </td>

                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                                                                        {t?.incentiveAmount}
                                                                    </td>


                                                                </tr>
                                                            </>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ > : <></>
                }



                <table className="items-center text-blueGray-700 w-[80%] rounded-lg bg-gray-200">

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Company Amount
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {companyDateWise?.toLocaleString("en-IN")}
                        </td>

                    </tr>
                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Company Incoming Amount
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {companyIncomingDateWise?.toLocaleString("en-IN")}
                        </td>

                    </tr>

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Lorry Amount
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 ">
                            {lorryDateWise?.toLocaleString('en-IN')}
                        </td>
                    </tr>

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Balance of Lorry
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {lorryBalDateWise?.toLocaleString("en-IN")}
                        </td>

                    </tr>
                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-base whitespace-nowrap p-4 text-left">
                            {`Estimated Profit  (sum of ton * 15)`}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {profitDateWise?.toLocaleString("en-IN")}
                        </td>
                    </tr>

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-base whitespace-nowrap p-4 text-left">
                            {`total Incentive`}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {incentiveDateWise?.toLocaleString("en-IN")}
                        </td>
                    </tr>
                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-base whitespace-nowrap p-4 text-left">
                            {`total expenses`}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {expenseDateWise?.toLocaleString("en-IN")}
                        </td>
                    </tr>
                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-base whitespace-nowrap p-4 text-left">
                            {`Profit ((Estimated Profit + Incentive) - Expense)`}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {((profitDateWise + incentiveDateWise) - expenseDateWise).toLocaleString("en-IN")}
                        </td>
                    </tr>

                </table>



                <h2 className='mt-4'>Calcultions Upto given date
                    {
                        showDownloadIcon ? <>
                            <i class=" ml-5 fa-solid fa-file-export text-lg  items-baseline text-orange-300 cursor-pointer" onClick={handleRangeDownload} title='Download Report'></i>
                        </> : <></>
                    }
                </h2>

                <div >
                    <form onSubmit={calculateUptillDate} className='flex items-center gap-5 mt-4 bg-gray-300 p-2 rounded-lg'>
                        <Input type="date" name='endDate' label={"End date"} className='w-[300px]' />
                        <Button type='submit' color='primary' className='font-bold hover:border hover:border-black'><i class="fa-solid fa-calculator"></i>calculate</Button>
                    </form>

                </div>


                <table className="items-center text-blueGray-700 w-[80%] rounded-lg bg-gray-200 mb-5">

                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Company Amount
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {(companySum + threshold?.companyTotal).toLocaleString("en-IN")}
                        </td>

                    </tr>
                    <tr className='border-b-1 border-black'>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4 text-left">
                            Lorry Amount
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap p-4">
                            {(lorrySum + threshold?.lorryTotal).toLocaleString("en-IN")}
                        </td>

                    </tr>

                </table>




            </div>


        </>
    );
};
export default Analytics;