import React, { useState } from "react";
import { Input, Button } from "@nextui-org/react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { generateOtp } from "../utils/OtpGenerator";

const Signup = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  const [showOpt, setShowOpt] = useState(true);

  const [generatedOtp, setgeneratedOtp] = useState();

  const [otp, setOtp] = useState(new Array(4).fill(""));

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleChange2 = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      // Focus on previous input
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getOtp = async () => {
    if (formData.name === "" || formData.email === "" || formData.password === "") {
      toast.error("All fields required");
      return
    }
    if (!(formData.email.includes("@") && formData.email.includes(".com"))) {
      toast.error("required email");
      return
    }

    try {
      const response = await axios.post("/user/isAdminExist");
      if (response.data.success) {
        if (formData.password.length < 8) {
          toast.error("password length must be 8 or more");
          return
        }
        const otp = generateOtp(4);
        //saving otp in usestate
        setgeneratedOtp(otp);

        const data = {
          mailTo: formData.email,
          subject: "One Time Password",
          text: `${otp} is your OTP (One Time Password), don't share it to anyone. This OTP is generated for verifying email to Register Admin.`,
        };

        try {
          toast.warn("Sending otp! please wait");
          const ress = await axios.post("/send-mail", data);
          if (ress.data.success) {
            toast.success(ress.data.message);
            setShowOpt(false);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let sOtp = otp.toString().replaceAll(",", "");

    if (sOtp === generatedOtp) {
      toast.success("OTP matched");
      try {
        const response = await axios.post("/user/createAdmin", formData);
        if (response.data.success) {
          toast.success("Admin SignUp successful");
          navigate("/");
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
    else {
      toast.error("OTP not matched")
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-screen  ">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col sm:w-[50vw] lg:w-[25vw] justify-center items-center border-2 p-5 bg-gray-100 rounded-lg"
        >
          {showOpt ? (
            <>
              <h1>Signup</h1>
              <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                <Input
                  name="name"
                  onChange={handleChange}
                  required
                  type="text"
                  variant="underlined"
                  label="Name"
                />
              </div>
              <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                <Input
                  name="email"
                  onChange={handleChange}
                  required
                  type="email"
                  variant="underlined"
                  label="Email"
                />
              </div>
              <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                <Input
                  required
                  label="Password"
                  onChange={handleChange}
                  name="password"
                  variant="underlined"
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                      aria-label="toggle password visibility"
                    >
                      {isVisible ? (
                        <i class="fa-solid fa-eye"></i>
                      ) : (
                        <i class="fa-solid fa-eye-slash"></i>
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                />
              </div>

              <div className="flex justify-evenly m-4 w-4/5 md:flex-col md:gap-2 2xl:flex-row">
                <span className="text-sm">Already have account? </span>
                <Link
                  to={"/"}
                  className="text-sm  text-blue-400 hover:text-black"
                >
                  Login <i class="fa-solid fa-up-right-from-square text-xs"></i>
                </Link>
              </div>
              <Button
                onClick={getOtp}
                color="primary"
                className="m-4 w-2/5 rounded-md"
              >
                Sign up
              </Button>
            </>
          ) : (
            <>
              <h1 className="font-bold text-2xl">Enter OTP Code</h1>
              <p className="text-sm mb-2">
                Sent code to{" "}
                <span className="text-blue-400">{formData.email}</span>
              </p>
              <div className="flex justify-center space-x-2">
                {otp.map((data, index) => {
                  return (
                    <input
                      className="w-12 h-12 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      type="text"
                      name="otp"
                      maxLength="1"
                      required
                      key={index}
                      value={data}
                      onChange={(e) => handleChange2(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}
              </div>
              <p className="mt-4 text-sm">
                Didn't get OTP?{" "}
                <Button
                  className=" border-none h-4 w-1/5 bg-gray-100 text-blue-400 rounded-md"
                  onClick={getOtp}
                >
                  Resend OTP
                </Button>
              </p>
              <Button
                type="submit"
                color="primary"
                className=" mt-4 w-2/5 rounded-md"
              >
                Verify OTP
              </Button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default Signup;
