import React from 'react'
import { Outlet, useNavigation } from 'react-router-dom'
import MyNavbar from '../components/Navbar'

const Layout = () => {

    let navigation = useNavigation();

    if (navigation.state === "loading") {
        return <h1>Loading..</h1>
    }

    return (
        <>
            <MyNavbar />
            <Outlet />
        </>
    )
}

export default Layout