import React from 'react'

const Loader = () => {
    return (
        <>
            <div className="w-[100vw] h-[80vh] flex justify-center items-center">
                <div class="flex-col gap-4 w-full flex items-center justify-center">
                    <div
                        class="w-28 h-28 border-8 border-transparent text-blue-400 text-4xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
                        <div
                            class="w-20 h-20 border-8 border-transparent text-red-400 text-2xl animate-spin flex items-center justify-center border-t-red-400 rounded-full">
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loader;