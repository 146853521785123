import React, { useEffect, useState } from "react";

import {
  User,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userSlice";
import { toast } from "react-toastify";
import axios from "axios";

const MyNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const { token, user } = useSelector((state) => state.user);


  const dispatch = useDispatch();

  const navigate = useNavigate();
  const menuItems = [
    // "Profile",
    // "Dashboard",
    // "Activity",
    // "Analytics",
    // "System",
    // "Deployments",
    // "My Settings",
    // "Team Settings",
    // "Help & Feedback",
    // "Log Out",
  ];

  const logoName = "LOGISTICS";

  const handleLogout = () => {
    dispatch(logout());
    toast.success("logout success");
    navigate("/");
  };

  // const getCurrentUser = async ()=>{
  //   try {

  //     const response = await axios.get(`/user/${user._id}`);
  //     if(response.data.success){
  //       setCurrentUser(response.data.user);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // useEffect(()=>{
  //   getCurrentUser();
  // },[])

  return (
    <>
      <Navbar
        className="h-[75px] bg-gray-200"
        isBordered
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
      >
        <NavbarContent className="sm:hidden" justify="start">
          <NavbarMenuToggle
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          />
        </NavbarContent>

        <NavbarContent className="sm:hidden pr-3" justify="center">
          <NavbarBrand>
            <p className="font-bold text-inherit">{logoName}</p>
          </NavbarBrand>
        </NavbarContent>

        <NavbarBrand className="hidden sm:block">
          <p className="font-bold text-inherit">{logoName}</p>
        </NavbarBrand>

        <NavbarContent className="hidden sm:flex gap-6" justify="center">
          {token ? (
            <NavbarItem>
              <NavLink to="/home" aria-current="page">
                Home
              </NavLink>
            </NavbarItem>
          ) : null}

          {token ? (
            <NavbarItem>
              <NavLink to="/all-records" aria-current="page">
                All Records
              </NavLink>
            </NavbarItem>
          ) : null}

          {/* Dropdown............................... */}
          {token ? (
            <Dropdown>
              <NavbarItem>
                <DropdownTrigger>
                  <Button
                    endContent={<i class="fa-solid fa-chevron-down mt-1"></i>}
                  >
                    Expense Tracker
                  </Button>
                </DropdownTrigger>
              </NavbarItem>

              <DropdownMenu>
                <DropdownItem onClick={() => navigate("/allExpenses")}>
                  All Expenses
                </DropdownItem>

                <DropdownItem onClick={() => navigate("/addExpenses")}>
                  Add Expenses
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : null}

          {/* Dropdown............................... */}

          {token && (user?.role === "Admin" || user?.role === "SubAdmin") ? (
            <Dropdown>
              <NavbarItem>
                <DropdownTrigger>
                  <Button
                    endContent={<i class="fa-solid fa-chevron-down mt-1"></i>}
                  >
                    Users
                  </Button>
                </DropdownTrigger>
              </NavbarItem>

              <DropdownMenu>
                <DropdownItem onClick={() => navigate("/allUsers")}>
                  All Users
                </DropdownItem>

                <DropdownItem onClick={() => navigate("/createUser")}>
                  Add User
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : null}

          {/* dropdown................................. */}
          {/* {token ? (
            <Dropdown>
              <NavbarItem>
                <DropdownTrigger>
                  <Button
                    disableRipple
                    className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                    endContent={<i class="fa-solid fa-chevron-down mt-1"></i>}
                    radius="sm"
                    variant="light"
                  >
                    Features
                  </Button>
                </DropdownTrigger>
              </NavbarItem>
              <DropdownMenu
                aria-label="ACME features"
                className="w-[340px]"
                itemClasses={{
                  base: "gap-4",
                }}
              >
                <DropdownItem
                  key="autoscaling"
                  description="ACME scales apps to meet user demand, automagically, based on load."
                  startContent={""}
                >
                  Autoscaling
                </DropdownItem>
                <DropdownItem
                  key="usage_metrics"
                  description="Real-time metrics to debug issues. Slow query added? We’ll show you exactly where."
                  startContent={""}
                >
                  Usage Metrics
                </DropdownItem>
                <DropdownItem
                  key="production_ready"
                  description="ACME runs on ACME, join us and others serving requests at web scale."
                  startContent={""}
                >
                  Production Ready
                </DropdownItem>
                <DropdownItem
                  key="99_uptime"
                  description="Applications stay on the grid with high availability and high uptime guarantees."
                  startContent={""}
                >
                  +99% Uptime
                </DropdownItem>
                <DropdownItem
                  key="supreme_support"
                  description="Overcome any challenge with a supporting team ready to respond."
                  startContent={""}
                >
                  +Supreme Support
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : null} */}
        </NavbarContent>

        <NavbarContent justify="end">
          {token ? (
            <NavbarItem className="lg:flex">
              <Dropdown placement="bottom-start">
                <DropdownTrigger>
                  <User
                    as="button"
                    avatarProps={{
                      isBordered: true,
                      src: `/profile.png`,
                    }}
                    className="transition-transform"
                    description={user?.role}
                    name={user?.name}
                  />
                </DropdownTrigger>
                <DropdownMenu aria-label="User Actions" variant="flat">
                  <DropdownItem key="profile" className="h-14 gap-2">
                    <p className="font-bold">Signed in as</p>
                    <p className="font-bold">{user?.email}</p>
                  </DropdownItem>
                  <DropdownItem key="updateUser" onClick={() => navigate(`/updateUser/${user._id}`)}>Update Profile</DropdownItem>
                  <DropdownItem key="changePassword" onClick={() => navigate('/changePassword')}>Change Password</DropdownItem>
                  {user.role === "Admin" ? <DropdownItem key="analytics" onClick={() => navigate('/analytics')}>Analytics</DropdownItem> : null}
                  <DropdownItem key="logout" color="danger" onClick={handleLogout}>
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {/* <Button
                color="warning"
                onClick={handleLogout}
                className="font-bold text-white"
              >
                logout
              </Button> */}
            </NavbarItem>
          ) : (
            <>
              <NavbarItem className="lg:flex">
                <Button color="success" className="text-white font-bold">
                  <NavLink to={"/"}>Login</NavLink>
                </Button>
              </NavbarItem>
            </>
          )}
        </NavbarContent>

        <NavbarMenu>
          {menuItems.map((item, index) => (
            <NavbarMenuItem key={`${item}-${index}`}>
              <NavLink
                className="w-full"
                color={
                  index === 2
                    ? "warning"
                    : index === menuItems.length - 1
                      ? "danger"
                      : "foreground"
                }
                href="#"
                size="lg"
              >
                {item}
              </NavLink>
            </NavbarMenuItem>
          ))}
        </NavbarMenu>
      </Navbar>
    </>
  );
};

export default MyNavbar;
