import { React, useEffect, useState } from "react";
import { Input, Button } from "@nextui-org/react";
import axios from "axios";
import { Autocomplete, AutocompleteItem } from "@nextui-org/react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateOtp } from "../../utils/OtpGenerator";
import { login } from "../../redux/userSlice";

const UpdateUser = () => {
  const param = useParams();

  const { token, user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
  });

  const [showOtpBtn, setShowOtpBtn] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setId(param.id);
    (async () => {
      const response = await axios.get(`/user/${param.id}`);
      if (response.data.user.role === "Admin" && user?.role !== "Admin") {
        toast.error("SubAdmin cannot update Admin details");
        navigate('/allUsers')
      }
    })()
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const [otp, setOtp] = useState(new Array(4).fill(""));

  const [generatedOtp, setgeneratedOtp] = useState();

  const handleChange2 = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      // Focus on previous input
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const submitDataWithMail = async (formData) => {
    try {
      let filteredData = {};
      if (formData.name) {
        filteredData = { ...filteredData, name: formData.name };
      }
      if (formData.role) {
        filteredData = { ...filteredData, role: formData.role };
      }
      filteredData = { ...filteredData, email: formData.email };

      const response = await axios.put(`/user/${id}`, filteredData);
      if (response.data.success) {
        toast.success(response.data.message);

        if (user?._id === id) {
          dispatch(login({
            user: response.data.user,
            token
          }))
        }
        navigate('/allUsers');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const submitDataWithoutMail = async (formData) => {
    try {
      let filteredData = {};
      if (formData.name) {
        filteredData = { ...filteredData, name: formData.name };
      }
      if (formData.role) {
        filteredData = { ...filteredData, role: formData.role };
      }

      const response = await axios.put(`/user/${id}`, filteredData);
      if (response.data.success) {
        console.log(response.data.user);

        toast.success(response.data.message);
        if (user?._id === id) {
          dispatch(login({
            user: response.data.user,
            token
          }))
        }
        navigate("/allUsers");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const [showOtp, setShowOtp] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowOtp(false);

    try {
      if (formData.email) {

        if (!showOtp) {
          //checking mail exist in db or not
          const isEmailExist = await axios.post("/user/checkEmailForSignUp", {
            email: formData.email,
          });

          if (isEmailExist.data.success) {
            const otp = generateOtp(4);
            setgeneratedOtp(otp);

            const data = {
              mailTo: formData.email,
              subject: "One Time Password",
              text: `${otp} is your OTP (One Time Password), don't share it to anyone. This OTP is generated for verifying email to changing Email-id of your Account.`,
            };
            toast.warn("Sending otp! please wait");
            const ress = await axios.post("/send-mail", data);
            if (ress.data.success) {
              toast.success(ress.data.message);
              setShowOtpBtn(false);
              setShowOtp(true);
            }
          }
        } else {
          let sOtp = otp.toString().replaceAll(",", "");

          if (sOtp === generatedOtp) {
            submitDataWithMail(formData);
          }
          else {
            toast.error("OTP not matched");
            setShowOtp(true);
          }
        }
      } else {
        submitDataWithoutMail(formData);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen  ">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col sm:w-[50vw] lg:w-[25vw] justify-center items-center border-2 p-5 bg-gray-100 rounded-lg"
        >
          {showOtpBtn ? (
            <>
              {/* <h1 className=''>Update User</h1> */}
              <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                <Input
                  name="name"
                  onChange={handleChange}
                  type="text"
                  variant="underlined"
                  label="Name"
                />
              </div>
              <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                <Input
                  name="email"
                  onChange={handleChange}
                  type="email"
                  variant="underlined"
                  label="Email"
                />
              </div>

              {user.role === "Admin" && user._id !== id ? (
                <div className="w-full m-4 ml-[23%]">
                  <Autocomplete
                    className="w-4/5"
                    name="role"
                    label="Select role"
                    onSelectionChange={(key) => {
                      const role = key;
                      setFormData({
                        ...formData,
                        role,
                      });
                    }}
                  >
                    <AutocompleteItem key="Admin">Admin</AutocompleteItem>
                    <AutocompleteItem key="SubAdmin">SubAdmin</AutocompleteItem>
                    <AutocompleteItem key="Accountant">
                      Accountant
                    </AutocompleteItem>
                  </Autocomplete>
                </div>
              ) : null}

              <Button
                // onClick={getOtp}
                type="submit"
                color="primary"
                className="m-4 w-2/5 rounded-md"
              >
                Submit
              </Button>
            </>
          ) : (
            <>
              <h1 className="font-bold text-2xl">Enter OTP Code</h1>
              <p className="text-sm mb-2">
                Sent code to{" "}
                <span className="text-blue-400">{formData.email}</span>
              </p>
              <div className="flex justify-center space-x-2">
                {otp.map((data, index) => {
                  return (
                    <input
                      className="w-12 h-12 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      type="text"
                      name="otp"
                      maxLength="1"
                      required
                      key={index}
                      value={data}
                      onChange={(e) => handleChange2(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}
              </div>
              {/* <p className="mt-4 text-sm">
                Didn't get OTP?{" "}
                <Button
                  className=" border-none h-4 w-1/5 bg-gray-100 text-blue-400 rounded-md"
                  onClick={handleSubmit}
                >
                  Resend OTP
                </Button>
              </p> */}
              <Button
                type="submit"
                color="primary"
                className=" mt-4 w-2/5 rounded-md"
              >
                Verify OTP
              </Button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default UpdateUser;
