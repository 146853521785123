import React, { useState } from "react";
import { Input, Button } from "@nextui-org/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const navigate = useNavigate();
  

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    let data = Object.fromEntries(formData.entries());


    if(data.newPassword.length < 8){
      toast.error("password length must be 8 or more");
      return
    }
   

      try {
        const response = await axios.patch(`/user/changePassword`, data);

        if (response.data.success) {
          toast.success(response.data.message);
          navigate('/home');
        }
      } catch (error) {
        toast.error(error.response.data.message);
      } 

  

  };

  return (
    <>
      <div className="flex justify-center items-center h-screen ">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-1/4 border-2 p-10 bg-gray-100 rounded-lg justify-center items-center"
        >
          <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Input
              required
              label="Old Password"
              name="oldPassword"
              variant="underlined"
              type="text"
            />
          </div>

          <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Input
              required
              label="New Password"
              name="newPassword"
              variant="underlined"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                  aria-label="toggle password visibility"
                >
                  {isVisible ? (
                    <i class="fa-solid fa-eye"></i>
                  ) : (
                    <i class="fa-solid fa-eye-slash"></i>
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
            />
          </div>

          <Button
            type="submit"
            color="primary"
            className=" mt-8 w-2/5 rounded-md"
          >
            Change Password
          </Button>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
