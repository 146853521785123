import { Button, Input } from '@nextui-org/react'
import axios from 'axios';
import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from "../redux/userSlice";

const Home = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post("/user/me");

      } catch (error) {
        dispatch(logout());
        navigate("/");
      }

    })();
  }, [])


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    const data = Object.fromEntries(formdata.entries());

    try {
      const response = await axios.post("/upload-file", data, { headers: { "Content-Type": 'multipart/form-data' } });

      if (response.data.success) {
        toast.success(response.data.message)
      }

    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  }

  const submitBill = async (e) => {
    try {
      e.preventDefault();
      const formdata = new FormData(e.target);
      const data = Object.fromEntries(formdata.entries())

      if (!data.billNo || !data.billDate || !data.difference) {
        toast.error("Bill No or Bill Date or ToPay difference required!");
        return;
      }

      const response = await axios.post("/upload-bill", data, { headers: { "Content-Type": "multipart/form-data" } });

      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  }

  const submitThreePoint = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const data = Object.fromEntries(form.entries());
    if (!data.threePointBill || !data.threePointDate) {
      toast.error("Required 3-point-bill or 3-point-date!");
      return;
    }
    try {
      const response = await axios.post("/three-point-bill", data, { headers: { "Content-Type": "multipart/form-data" } })
      if (response.data.success) {
        toast.success(response.data.message);
      }

    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const submitTollBill = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const data = Object.fromEntries(form.entries());
    try {
      const response = await axios.post("/toll-bill", data, { headers: { "Content-Type": "multipart/form-data" } });
      if (response.data.success) {
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }

  }

  const submitIncentive = async (e) => {
    e.preventDefault();

    const form = new FormData(e.target);
    const data = Object.fromEntries(form.entries());
    try {
      const response = await axios.post("/incentive-bill", data, { headers: { "Content-Type": "multipart/form-data" } });
      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const submitUnloading = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const data = Object.fromEntries(form.entries());
    try {
      const response = await axios.post("unloading-bill", data, { headers: { "Content-Type": "multipart/form-data" } });
      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }


  return (
    <>
      <div className='space-y-4 py-4 bg-gray-100'>

        <div className=" py-4 space-y-5 m-3 rounded-2xl shadow bg-white">
          <p className="font-bold text-2xl italic underline text-center">Upload Trip data</p>
          <div className='flex justify-center items-center gap-5'>
            <a href={"/templates/TransporterSheet.png"}><i title="Format for uploading file" class="fa-solid fa-circle-info text-red-500 hover:text-red-600 cursor-pointer"></i></a>
            <form onSubmit={handleSubmit} className='flex gap-2 justify-center'>
              <Input type="file" name='sheet' className="w-[100%]" required />
              <Button type='submit' color='primary' className="font-bold">Upload file</Button>
            </form>
            <p className='font-bold text-2xl'>OR</p>
            <div>
              <Button color='primary' onClick={() => navigate('/addTripManually')} className='font-bold'>Add Manually</Button>
            </div>
          </div>
        </div>

        <div className=" py-4 space-y-5 m-3 rounded-2xl shadow bg-white">
          <p className="font-bold text-2xl italic underline text-center">
            Upload Bill
            <a href={"/templates/Bill.png"}>
              <i title="Format for uploading file" class="fa-solid fa-circle-info text-base pl-2 text-red-500 hover:text-red-600 cursor-pointer"></i>
            </a>
          </p>
          <div className='flex justify-center gap-5'>
            <form onSubmit={submitBill} className='flex flex-col space-y-2 w-[60vw] sm:w-[40vw]'>
              <div className='space-y-2'>
                <Input type="text" name="difference" label="Company-lorry (Rate difference)" isRequired />
                <Input type='text' name='billNo' label="Bill No" isRequired />
                <Input type='date' name='billDate' label="Bill Date" isRequired />
              </div>
              <Input type="file" name='bill' className="w-[100%]" required />
              <Button type='submit' color='primary' className="font-bold">submit</Button>
            </form>
          </div>
        </div>

        <div className=" py-4 space-y-5 m-3 rounded-2xl shadow bg-white">
          <p className="font-bold text-2xl italic underline text-center">
            Upload 3-Point-Bill
            <a href={"/templates/ThreePointBill.png"}>
              <i title="Format for uploading file" class="fa-solid fa-circle-info text-base pl-2 text-red-500 hover:text-red-600 cursor-pointer"></i>
            </a>
          </p>
          <div className='flex justify-center gap-5'>
            <form onSubmit={submitThreePoint} className=' flex flex-col space-y-2 w-[60vw] sm:w-[40vw]'>
              <Input type='text' name='threePointBill' label="3 Point Bill No" isRequired />
              <Input type='date' name='threePointDate' label="3 Point Date" isRequired />
              <Input type="file" name='threePoint' className="w-[100%]" required />
              <Button type='submit' color='primary' className="font-bold">submit</Button>
            </form>
          </div>
        </div>

        <div className=" py-4 space-y-5 m-3 rounded-2xl shadow bg-white">
          <p className="font-bold text-2xl italic underline text-center">
            Upload Toll-Bill
            <a href={"/templates/TollBill.png"}>
              <i title="Format for uploading file" class="fa-solid fa-circle-info text-base pl-2 text-red-500 hover:text-red-600 cursor-pointer"></i>
            </a>
          </p>
          <div className='flex justify-center gap-5'>
            <form onSubmit={submitTollBill} className=' flex flex-col space-y-2 w-[60vw] sm:w-[40vw]'>
              <div className='space-y-2'>
                <Input type='text' name='tollBillNo' label="Toll-Bill No" required />
              </div>
              <Input type='date' name='tollDate' label="Toll Bill Date" required />
              <Input type="file" name='tollBill' className="w-[100%]" required />
              <Button type='submit' color='primary' className="font-bold">submit</Button>
            </form>
          </div>
        </div>

        <div className=" py-4 space-y-5 m-3 rounded-2xl shadow bg-white">
          <p className="font-bold text-2xl italic underline text-center">
            Upload Incentive-Bill
            <a href={"/templates/incentive.png"}>
              <i title="Format for uploading file" class="fa-solid fa-circle-info text-base pl-2 text-red-500 hover:text-red-600 cursor-pointer"></i>
            </a>
          </p>
          <div className='flex justify-center gap-5'>
            <form onSubmit={submitIncentive} className=' flex flex-col space-y-2 w-[60vw] sm:w-[40vw]'>
              <Input type="file" name='incentive' className="w-[100%]" required />
              <Button type='submit' color='primary' className="font-bold">submit</Button>
            </form>
          </div>
        </div>

        <div className=" py-4 space-y-5 m-3 rounded-2xl shadow bg-white">
          <p className="font-bold text-2xl italic underline text-center">
            Upload Unloading-bill
            <a href={"/templates/unloading.png"}>
              <i title="Format for uploading file" class="fa-solid fa-circle-info text-base pl-2 text-red-500 hover:text-red-600 cursor-pointer"></i>
            </a>
          </p>
          <div className='flex justify-center gap-5'>
            <form onSubmit={submitUnloading} className=' flex flex-col space-y-2 w-[60vw] sm:w-[40vw]'>
              <Input type='text' name='unloadingBill' label='Unloading Bill-No' required />
              <Input type='date' name='unloadingDate' label="Unloading Bill-Date" />
              <Input type="file" name='unloading' className="w-[100%]" required />
              <Button type='submit' color='primary' className="font-bold">submit</Button>
            </form>
          </div>
        </div>

      </div>
    </>
  )
}

export default Home
