import React, { useState } from "react";
import { Input, Button } from "@nextui-org/react";
import axios from "axios";
import { toast } from "react-toastify";
import { generateOtp } from "../utils/OtpGenerator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyOtp } from "../redux/userSlice";

const UserVerification = () => {
  const [showOtpBtn, setShowOtpBtn] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState(new Array(4).fill(""));

  const [generatedOtp, setgeneratedOtp] = useState();

  const handleChange2 = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      // Focus on previous input
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getOtp = async (e) => {
    if (!formData.email.includes("@") && !formData.email.includes('.com')) {
      toast.error("Invalid email!");
      return;
    }
    try {
      const isEmailExist = await axios.post("/user/checkEmail", { email: formData.email });
      if (isEmailExist.data.success) {
        const otp = generateOtp(4);
        //saving otp in usestate
        setgeneratedOtp(otp);

        const data = {
          mailTo: formData.email,
          subject: "One Time Password",
          text: `${otp} is your OTP (One Time Password), don't share it to anyone. This OTP is generated for verifying email to reset password.`,
        };

        try {
          toast.warn("Sending otp! please wait");
          const ress = await axios.post("/send-mail", data);
          if (ress.data.success) {
            toast.success(ress.data.message);

            //email is given and otp is sent successfully
            if (formData.email !== "") {
              //shows otp entering interface
              setShowOtpBtn(false);
            }
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      navigate("/");

    }


  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let sOtp = otp.toString().replaceAll(",", "");

    if (sOtp === generatedOtp) {
      toast.success("OTP verified");
      dispatch(verifyOtp());
      navigate(`/resetPassword/${formData.email}`);
    } else {
      toast.error("OTP mismatch");
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-screen ">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col border-2 sm:w-[50vw] lg:w-[25vw] p-10 bg-gray-100 rounded-lg justify-center items-center"
        >
          {showOtpBtn ? (
            <>
              <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                <Input
                  name="email"
                  onChange={handleChange}
                  required
                  type="email"
                  variant="underlined"
                  label="Email"
                />
              </div>
              <Button
                color="primary"
                onClick={getOtp}
                className=" mt-8 w-2/5 rounded-md font-semibold"
              >
                Get OTP
              </Button>
            </>
          ) : (
            <>
              <h1 className="font-bold text-2xl">Enter OTP Code</h1>
              <p className="text-sm mb-2">
                Sent code to{" "}
                <span className="text-blue-400">{formData.email}</span>
              </p>
              <div className="flex justify-center space-x-2">
                {otp.map((data, index) => {
                  return (
                    <input
                      className="w-12 h-12 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      type="text"
                      name="otp"
                      maxLength="1"
                      required
                      key={index}
                      value={data}
                      onChange={(e) => handleChange2(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}
              </div>
              <p className="mt-4 text-sm">
                Didn't get OTP?{" "}
                <Button
                  className=" border-none h-4 w-1/5 bg-gray-100 text-blue-400 rounded-md"
                  onClick={getOtp}
                >
                  Resend OTP
                </Button>
              </p>
              <Button
                type="submit"
                color="primary"
                className=" mt-4 w-2/5 rounded-md"
              >
                Verify OTP
              </Button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default UserVerification;
