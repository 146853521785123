import { React, useState } from "react";
import { Input, Button } from "@nextui-org/react";
import axios from "axios";
import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { generateOtp } from "../../utils/OtpGenerator.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateUser = () => {
  const [isVisible, setIsVisible] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
  });

  const [generatedOtp, setgeneratedOtp] = useState();
  const [showOtpBtn, setShowOtpBtn] = useState(true);

  const [otp, setOtp] = useState(new Array(4).fill(""));

  const navigate = useNavigate();

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleChange2 = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      // Focus on previous input
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value
    })

  }
  const getOtp = async () => {
    try {



      const isEmailExist = await axios.post("/user/checkEmailForSignUp", {
        email: userData?.email,
      });



      if (isEmailExist.data.success) {

        if (userData.password.length < 8) {
          toast.error("Password length must be 8 or more");
          return
        }

        const otp = generateOtp(4);
        //saving otp in usestate
        setgeneratedOtp(otp);

        const data = {
          mailTo: userData.email,
          subject: "One Time Password",
          text: `${otp} is your OTP (One Time Password), don't share it to anyone. This OTP is generated for verifying email to Signing a new Account.`,
        };
        toast.warn("Sending otp! please wait");
        const ress = await axios.post("/send-mail", data);
        if (ress.data.success) {
          toast.success(ress.data.message);

          //email is given and otp is sent successfully
          if (userData.email !== "") {
            //shows otp entering interface
            setShowOtpBtn(false);
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      let sOtp = otp.toString().replaceAll(",", "");

      if (sOtp === generatedOtp) {
        const response = await axios.post("/user/signUp", userData);
        if (response.data.success) {
          toast.success(response.data.message);
          navigate("/allUsers");
        }
      } else {
        toast.error("OTP mismatch");
      }

    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-screen  ">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col sm:w-[50vw] lg:w-[25vw] justify-center items-center border-2 p-5 bg-gray-100 rounded-lg"
        >
          {showOtpBtn ? (
            <>
              {/* <h1>Register User</h1> */}
              <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                <Input
                  name="name"
                  onChange={handleChange}
                  required
                  type="text"
                  variant="underlined"
                  label="Name"
                />
              </div>
              <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                <Input
                  name="email"
                  onChange={handleChange}
                  required
                  type="email"
                  variant="underlined"
                  label="Email"
                />
              </div>
              <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                <Input
                  required
                  label="Password"
                  onChange={handleChange}
                  name="password"
                  variant="underlined"
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                      aria-label="toggle password visibility"
                    >
                      {isVisible ? (
                        <i class="fa-solid fa-eye"></i>
                      ) : (
                        <i class="fa-solid fa-eye-slash"></i>
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                />
              </div>

              <div className="w-full m-4 ml-[23%]">
                <Autocomplete className="w-4/5" name="role" label="Select role"
                  onSelectionChange={(key) => {
                    const role = key;
                    setUserData({
                      ...userData,
                      role,
                    });
                  }}
                >
                  <AutocompleteItem key="SubAdmin">SubAdmin</AutocompleteItem>
                  <AutocompleteItem key="Accountant">
                    Accountant
                  </AutocompleteItem>
                </Autocomplete>
              </div>

              <Button
                onClick={getOtp}
                color="primary"
                className="m-4 w-2/5 rounded-md"
              >
                Get OTP
              </Button>
            </>
          ) : (
            <>
              <h1 className="font-bold text-2xl">Enter OTP Code</h1>
              <p className="text-sm mb-2">
                Sent code to{" "}
                <span className="text-blue-400">{userData.email}</span>
              </p>
              <div className="flex justify-center space-x-2">
                {otp.map((data, index) => {
                  return (
                    <input
                      className="w-12 h-12 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      type="text"
                      name="otp"
                      maxLength="1"
                      required
                      key={index}
                      value={data}
                      onChange={(e) => handleChange2(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}
              </div>
              <p className="mt-4 text-sm">
                Didn't get OTP?{" "}
                <Button
                  className=" border-none h-4 w-1/5 bg-gray-100 text-blue-400 rounded-md"
                  onClick={getOtp}
                >
                  Resend OTP
                </Button>
              </p>
              <Button
                type="submit"
                color="primary"
                className=" mt-4 w-2/5 rounded-md"
              >
                Verify OTP
              </Button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default CreateUser;
