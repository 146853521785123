import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: {},
    token: "",
    otpVerified : false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        //login({user, token})
        login: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logout: (state, action) => {
            state.user = {}
            state.token = ""
        },
        verifyOtp : (state, action) => {
            state.otpVerified = true
        },
        unVerifyOtp : (state, action) => {
            state.otpVerified = false
        }

    }
})

export const { login, logout, verifyOtp, unVerifyOtp  } = userSlice.actions;

export default userSlice.reducer;