import React, { useEffect, useState } from "react";
import { Input, Button } from "@nextui-org/react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../redux/userSlice";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useSelector(state => state.user);

  useEffect(() => {
    if (token) {
      navigate('/home');
    }
  }, [])

  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/user/login", formData);

      if (response.data.success) {
        let user = response.data.user; 
        let token = response.data.token;

        dispatch(login({ user, token }));
        toast.success("Login success");
        navigate('/home');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <div className="flex justify-center items-center h-screen ">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col border-2 sm:w-[50vw] lg:w-[25vw] p-10 bg-gray-100 rounded-lg justify-center items-center"
        >
          <h1>Login</h1>
          <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Input name="email" required
              value={formData.email} onChange={handleChange} type="email" variant="underlined" label="Email" />
          </div>

          <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Input onChange={handleChange}
              required
              label="Password"
              name="password"
              value={formData.password}
              variant="underlined"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                  aria-label="toggle password visibility"
                >
                  {isVisible ? (
                    <i class="fa-solid fa-eye"></i>
                  ) : (
                    <i class="fa-solid fa-eye-slash"></i>
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}

            />
          </div>

          <div className="flex gap-6 mt-7 w-full ml-[25%]">
            <Link to={'/userVerification'} className="text-sm text-blue-600 hover:text-black">Forget Password? <i class="fa-solid fa-up-right-from-square text-xs"></i></Link>
            {/* <Link to={'/signup'} className="text-sm  text-blue-600 hover:text-black">Don't have account? <i class="fa-solid fa-up-right-from-square text-xs"></i></Link> */}
            <></>
          </div>

          <Button type="submit" color="primary" className="m-4 w-2/5 rounded-md">
            Log in
          </Button>
        </form>
      </div>
    </>
  );
};

export default Login;
