import { Input, Button } from "@nextui-org/react";
import { toast } from "react-toastify";
import axios from "axios";


const AddTrip = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    data.tripDate = new Date(data.date + "T" + data.time);
    delete data.date;
    delete data.time;
    console.log(data);


    try {

      const response = await axios.post('/trip/createTrip', data);
      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const star = <span className="text-red-600 font-bold">*</span>;

  return (
    <>
      <div className="flex justify-center items-center">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-[90vw] sm:w-[60vw]  lg:w-[50vw] justify-center items-center border-2 p-10 pb-2 my-5 gap-6 bg-gray-100 rounded-lg">

          <h1 className="underline font-bold">Add Trip</h1>
          {/* <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Input
              name="gpiNo"
              //   onChange={handleChange}
              required
              type="text"
              variant="underlined"
              label="GPI No"
            />
          </div>
          <Button
            onClick={getGpi}
            color="primary"
            className="m-4 w-2/5 rounded-md"
          >
            Next
          </Button> */}

          <div className="flex gap-4 w-full">
            <div>
              <label htmlFor="date" className="text-sm pl-3">Date {star}</label>
              <Input type="date" id="date" name="date" required />
            </div>
            <div>
              <label htmlFor="time" className="text-sm pl-3">time {star}</label>
              <Input type="time" id="time" name="time" required />
            </div>
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              name="gpiNo"
              isRequired
              required
              type="number"
              variant="underlined"
              label={`GPI No`}
            />
          </div>

          <div className="flex gap-4 w-full">
            <Input
              name="ton"
              isRequired
              required
              type="number"
              variant="underlined"
              label="Ton"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              name="lrNo"
              isRequired
              required
              type="number"
              variant="underlined"
              label="LR No"
            />

          </div>

          <div className="flex gap-4 w-full">
            <Input
              className="w-3/4"
              name="destination"
              isRequired
              required
              type="text"
              variant="underlined"
              label="Destination"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              name="truckNo"
              isRequired
              required
              type="text"
              variant="underlined"
              label="Truck No"
            />
          </div>

          <div className="flex gap-4 w-full">
            <Input
              className=""
              name="threePointAmount"
              type="number"
              variant="underlined"
              label="3 point Amount"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              className=""
              name="threePointBill"
              type="text"
              variant="underlined"
              label="3 point Bill"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              className=""
              name="threePointDate"
              type="date"
              variant="underlined"
              label="3 point Date"
            />
          </div>

          <div className="flex gap-4 w-full">
            <Input
              name="companyRate"
              type="number"
              variant="underlined"
              label="Company Rate"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              name="lorryRate"
              type="number"
              variant="underlined"
              label="Lorry Rate"
            />
            {/* <span className="w-[1px] h-10 mt-4 bg-black"></span> */}
            {/* <Input
              name="totalAmount"
              type="number"
              variant="underlined"
              label="Total Amount"
              
            /> */}
          </div>

          <div className="flex gap-4 w-full">
            <Input
              className="w-3/5"
              name="soldToParty"
              type="text"
              variant="underlined"
              label="Sold To Party"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              name="contactNo"
              className="w-2/5"
              type="number"
              variant="underlined"
              label="Contact No"
            />
          </div>

          <div className="flex gap-4 w-full">
            <Input
              name="advancedAmount"
              type="number"
              variant="underlined"
              label="Advanced Amount"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              name="advancedAmountDate"
              type="date"
              variant="underlined"
              label="Advanced Amount Date"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              name="dieselAmount"
              type="number"
              variant="underlined"
              label="Diesel Amount"
            />

          </div>

          <div className="flex gap-4 w-full">
            <Input
              className="w-3/5"
              name="billNo"
              type="text"
              variant="underlined"
              label="Bill No"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              name="billDate"
              className="w-2/5"
              type="date"
              variant="underlined"
              label="Bill Date"
            />

          </div>

          <div className="flex gap-4 w-full">
            <Input
              name="tollBillNo"
              className="w-2/5"
              type="text"
              variant="underlined"
              label="Toll-Bill-No"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              className="w-3/5"
              name="tollAmount"
              type="text"
              variant="underlined"
              label="Toll Amount"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>
            <Input
              className="w-3/5"
              name="tollDate"
              type="date"
              variant="underlined"
              label="Toll Date"
            />
          </div>

          <div className="flex gap-4 w-full">
            <Input
              name="incentiveAmount"
              className="w-3/5"
              type="number"
              variant="underlined"
              label="Incentive Amount"
            />

          </div>

          <div className="flex gap-4 w-full">

            <Input
              className="w-3/5"
              name="extraAmount"
              type="number"
              variant="underlined"
              label="Extra Amount"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>

            <Input
              name="extraAmountRemark"
              className="w-2/5"
              type="text"
              variant="underlined"
              label="Extra Amount Remark"
            />
            <span className="w-[1px] h-10 mt-4 bg-black"></span>

            <Input
              name="unloading"
              className="w-2/5"
              type="text"
              variant="underlined"
              label="Unloading"
            />
          </div>

          <Button
            type="submit"
            color="primary"
            className="m-4 w-2/5 rounded-md">
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};
export default AddTrip;
