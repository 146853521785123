import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import * as xlsx from 'xlsx'
import { Pagination, Input } from "@nextui-org/react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AllRecords = () => {


  const navigate = useNavigate();

  const { user } = useSelector(state => state.user);

  const [uichange, setuichange] = useState(false);

  const [paginationOn, setPaginationOn] = useState(true);
  const [trips, settrips] = useState([]);
  const [page, setPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState()

  const [loading, setloading] = useState(false);

  const [deleteOption, setDeleteOption] = useState(false);
  const [checkedRecords, setCheckedRecords] = useState([]);

  const getAllTrips = async () => {
    try {
      setPaginationOn(true);
      setloading(true);
      const response = await axios.get(`/trip/get/all?page=${page}`);
      setloading(false);
      if (response.data.success) {
        setTotalEntries(response.data.totalEntries);
        settrips(response.data.tripData);
        setTotalPages(response.data.totalPages);
      }
    } catch (error) {
      setloading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    getAllTrips();
  }, [page, uichange]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (!selectedItem) {
      setLable("Search");
    }
  }, []);

  const [label, setLable] = useState("Search");
  const [selectedItem, setSelectedItem] = useState("Select Item");

  const handleSearchItem = (e) => {
    const s = e.target.value;
    if (s === "") {
      getAllTrips();
    }
  };

  const handleSearchBar = async (e) => {
    const s = e.target.value;

    if (e.key === "Enter") {
      if (selectedItem.currentKey === "GPI No") {
        try {
          let formData = {
            gpiNo: s,
          };

          const response = await axios.get("/trip/get/byGpi", {
            params: formData,
          });
          if (response.data.success) {
            settrips(response.data.tripData);
            setPaginationOn(false);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else if (selectedItem.currentKey === "LR No") {
        try {
          let formData = {
            lrNo: s,
          };

          const response = await axios.get("/trip/get/byLrNo", {
            params: formData,
          });
          if (response.data.success) {
            settrips(response.data.tripData);
            setPaginationOn(false);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else if (selectedItem.currentKey === "Truck No") {
        try {
          let formData = {
            truckNo: s
          };
          const response = await axios.get("/trip/get/byTruckNo", { params: formData });
          if (response.data.success) {
            settrips(response.data.tripData);
            setPaginationOn(false);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else if (selectedItem.currentKey === "Bill No") {
        try {
          let formData = {
            billNo: s
          };
          const response = await axios.get("/trip/get/tripByBill", { params: formData });
          if (response.data.success) {
            settrips(response.data.tripData);
            setPaginationOn(false);
          }

        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    }
  };

  const handleDateSearch = async (e) => {
    const date = e.target.value;
    if (date === "") {
      getAllTrips();
    } else {
      try {
        const response = await axios.get("/trip/get/byDate", {
          params: { date },
        });
        if (response.data.success) {
          settrips(response.data.tripData);
          setPaginationOn(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  const [showDateInput, setShowDateInput] = useState(false);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: ""
  })

  const handleDate = (e) => {
    const { name, value } = e.target;
    setDates({
      ...dates,
      [name]: value
    })
  }

  const handleDownload = async () => {

    if (dates.startDate === "" || dates.endDate === "") {
      toast.error('dates required');
      return
    }

    console.log(dates);
    try {

      const response = await axios.post('/trip/get/tripsByDateRange', dates);
      if (response.data.success) {
        let sheet = [];
        response.data.trips?.forEach((trip) => {
          const formatDate = (date) => new Date(date).toLocaleDateString('en-GB');
          let date = new Date(trip.tripDate).toLocaleDateString('en-GB') + "-" + new Date(trip.tripDate).toLocaleTimeString('en-GB');
          let obj = {
            tripDate: date,
            gpiNo: trip.gpiNo || 0,
            ton: trip.ton || 0,
            lrNo: trip.lrNo || '',
            truckNo: trip.truckNo || '',
            destination: trip.destination || '',
            threePointAmount: trip.threePointAmount || 0,
            threePointBill: trip.threePointBill || '',
            threePointDate: trip.threePointDate ? formatDate(trip.threePointDate) : '',
            companyRate: trip.companyRate || 0,
            companyTotal: trip.companyTotal || 0,
            lorryRate: trip.lorryRate || 0,
            lorryTotal: trip.lorryTotal || 0,
            advancedAmount: trip.advancedAmount || 0,
            advancedAmountDate: trip.advancedAmountDate ? formatDate(trip.advancedAmountDate) : '',
            dieselAmount: trip.dieselAmount || 0,
            balanceAmount: trip.balanceAmount || 0,
            billNo: trip.billNo || '',
            billDate: trip.billDate ? formatDate(trip.billDate) : '',
            tollBillNo: trip.tollBillNo || '',
            tollAmount: trip.tollAmount || 0,
            tollDate: trip.tollDate ? formatDate(trip.tollDate) : '',
            soldToParty: trip.soldToParty || '',
            contactNo: trip.contactNo || 0,
            postPaidAmount: trip.postPaidAmount || 0,
            postPaidDate: trip.postPaidDate ? formatDate(trip.postPaidDate) : '',
            postPaid2Amount: trip.postPaid2Amount || 0,
            postPaid2Date: trip.postPaid2Date ? formatDate(trip.postPaid2Date) : '',
            postPaid3Amount: trip.postPaid3Amount || 0,
            postPaid3Date: trip.postPaid3Date ? formatDate(trip.postPaid3Date) : '',
            extraAmount: trip.extraAmount || 0,
            extraAmountRemark: trip.extraAmountRemark || '',
            unloading: trip.unloading || 0
          };

          sheet.push(obj);

        });

        // sheet = sheet.reverse();

        const workSheet = xlsx.utils.json_to_sheet(sheet);
        const workBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
        xlsx.writeFile(workBook, 'AllRecord.xlsx');

      }

    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const deleteTrip = async (id) => {
    try {
      const result = window.confirm("Are you sure? want to delete this record?");

      if (result) {
        const response = await axios.delete(`/trip/deleteTrip/${id}`);
        if (response.data.success) {
          setuichange(!uichange);
          toast.success(response.data.message);
        }
      } else {
        return;
      }

    } catch (error) {
      toast.error(error.response.data.message);
    }
  }


  const deleteSelectedRecords = async () => {

    try {
      const response = await axios.post("/trip/deleteManyTrips", { tripsId: checkedRecords });
      if (response.data.success) {
        toast.success(response.data.message);
        setuichange(!uichange);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }

  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="flex flex-row justify-around items-center m-5">
        <div>
          <Input
            label={"Search by date"}
            type="date"
            className="max-w-sm"
            onChange={handleDateSearch}
          />
        </div>
        <div className="flex gap-2 items-center">
          <Input
            type="Search"
            label={label}
            onChange={handleSearchItem}
            onKeyDown={handleSearchBar}
          />
          <Dropdown className="">
            <DropdownTrigger>
              <Button variant="bordered">{selectedItem}</Button>
            </DropdownTrigger>
            <DropdownMenu
              className=" text-center"
              selectedKeys={selectedItem}
              onSelectionChange={setSelectedItem}
              selectionMode="single"
            // onAction={(key) => {
            //   setLable(s + key);
            //   setSelectedItem(key);
            // }}
            >
              <DropdownItem key="GPI No">GPI No</DropdownItem>
              <DropdownItem key="Bill No">Bill No</DropdownItem>
              <DropdownItem key="LR No">LR No</DropdownItem>
              <DropdownItem key="Truck No">Truck No</DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Button className="text-white" onClick={() => setShowDateInput(!showDateInput)} color="warning">Download</Button>
        </div>
      </div>
      <div className="flex justify-evenly">
        <p></p>
        {
          showDateInput ? <>
            <div className="flex flex-col">
              <h3>Download Records</h3>
              <div className="flex items-baseline gap-4 w-[8rem] h-[3rem]">
                <Input
                  name="startDate"
                  label={"From date"}
                  type="date"
                  className="max-w-sm"
                  onChange={handleDate}
                />
                <Input
                  name="endDate"
                  label={"To date"}
                  type="date"
                  className="max-w-sm"
                  onChange={handleDate}
                />
                <i className="fa-solid fa-file-export text-2xl  items-baseline text-orange-300 cursor-pointer" onClick={handleDownload} ></i>
              </div>
            </div>
          </> : <></>
        }
      </div>
      <div>
        <div className="flex justify-between">
          <p className="ml-10 underline">Total Entries: {totalEntries}</p>
          {deleteOption ? <Button onClick={deleteSelectedRecords} color="danger"><i className="fa-solid fa-trash"></i></Button> : null}
          <Button onClick={() => setDeleteOption(!deleteOption)}
            color="danger"
            className="mr-3">{deleteOption ?
              <span onClick={() => setCheckedRecords([])}>Disable Delete</span>
              :
              <span>Delete Records</span>}
          </Button>
        </div>

        <div className="flex flex-col mx-4">
          <div className="-m-1.5 overflow-x-auto  bottom-0 inset-x-0">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="border rounded-lg overflow-hidden mt-5 shadow">
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      {
                        deleteOption ?
                          <th
                            scope="col"
                            className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                          ></th> : <></>
                      }
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Edit
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      ></th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        TRIP DATE
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        GPI No.
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        TON
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        LR No
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Truck No.
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Destination
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-smm text-gray-500 uppercase"
                      >
                        COMPANY RATE
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-smm text-gray-500 uppercase"
                      >
                        COMPANY TOTAL
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-smm text-gray-500 uppercase"
                      >
                        LORRY RATE
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        LORRY TOTAL
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        ADVANCE AMOUNT
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        ADVANCE AMOUNT REMARK
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        ADVANCE AMOUNT DATE
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        DIESEL AMOUNT
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        BALANCE AMOUNT
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        SOLD TO PARTY
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        CONTACT
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        POST PAID 1 DATE
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        POST PAID 1 AMOUNT
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        POST PAID 2 DATE
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        POST PAID 2 AMOUNT
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        POST PAID 3 DATE
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        POST PAID 3 AMOUNT
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        POST-PAID AMOUNT REMARK
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Extra Amount
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Extra Amount Remark
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Unloading
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Unloading Date
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Unloading Bill
                      </th>



                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Bill DATE
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        Bill NO
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        TOLL BILL DATE
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        TOLL BILL NO
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        TOLL AMOUNT
                      </th>


                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        3 POINT DATE
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        3 POINT BILL
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-smm text-gray-500 uppercase"
                      >
                        3 POINT AMT
                      </th>


                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        INCENTIVE AMOUNT
                      </th>



                      {user?.role === "Admin" ? <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-sm text-gray-500 uppercase"
                      >
                        DELETE
                      </th> : null}

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {trips?.map((t, index) => {
                      return (
                        <>
                          <tr className="hover:bg-gray-300 cursor-pointer">

                            {
                              deleteOption ? <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                                <input onChange={(e) => {
                                  if (e.target.checked) {
                                    setCheckedRecords([...checkedRecords, e.target.value])
                                  }
                                  else {
                                    let arr = checkedRecords?.filter((id) => {
                                      return id !== t._id
                                    })
                                    setCheckedRecords(arr);
                                  }

                                }}
                                  type="checkbox" value={t._id} />
                              </td> : null
                            }

                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              <i onClick={() => navigate(`/updateTrip/${t._id}`)}
                                className="fa-solid fa-pen-nib text-blue-700 hover:scale-150 transition-transform"></i>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {index + 1}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {new Date(t?.tripDate).toLocaleDateString(
                                "en-GB"
                              ) +
                                " - " +
                                new Date(t?.tripDate).toLocaleTimeString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.gpiNo}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.ton}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.lrNo}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.truckNo}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.destination}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.companyRate}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.companyTotal}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.lorryRate}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.lorryTotal}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.advancedAmount}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.advanceAmountRemark}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t.advancedAmountDate ? new Date(t?.advancedAmountDate).toLocaleDateString("en-GB") : "-"}
                            </td>
                            <td className=" px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.dieselAmount}
                            </td>
                            <td className={`${(t?.balanceAmount === 0 && t?.lorryRate !== 0) ? 'text-green-600 font-bold bg-green-100' : t?.balanceAmount < 0 ? 'text-red-500 font-bold bg-red-100' : 'text-black'} px-6 py-4 whitespace-nowrap text-sm font-medium`}>
                              {t?.balanceAmount}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.soldToParty}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.contactNo}
                            </td>


                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.postPaidDate ? new Date(t?.postPaidDate)?.toLocaleDateString("en-GB") : "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.postPaidAmount}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.postPaid2Date ? new Date(t?.postPaid2Date)?.toLocaleDateString("en-GB") : "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.postPaid2Amount}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.postPaid3Date ? new Date(t?.postPaid3Date)?.toLocaleDateString("en-GB") : "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.postPaid3Amount}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.postPaidAmountRemark}
                            </td>

                            <td className={t?.extraAmount ? "px-6 py-4 whitespace-nowrap text-sm font-medium text-red-500 bg-red-100" : null}>
                              {t?.extraAmount}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.extraAmountRemark}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.unloading}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.unloadingDate ? new Date(t?.unloadingDate).toLocaleDateString("en-GB") : "-"}
                            </td>
                            <td className={t?.unloadingBill ? "px-6 py-4 whitespace-nowrap text-sm font-medium text-green-700 bg-green-100" : null}>
                              {t?.unloadingBill}
                            </td>


                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.billDate ? new Date(t?.billDate).toLocaleDateString("en-GB") : "-"}
                            </td>
                            <td className={t?.billNo ? "text-green-700 bg-green-100 px-6 py-4 whitespace-nowrap text-sm font-medium" : null}>
                              {t?.billNo}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.tollDate ? new Date(t?.tollDate).toLocaleDateString("en-GB") : "-"}
                            </td>
                            <td className={t?.tollBillNo ? "text-green-700 bg-green-100 px-6 py-4 whitespace-nowrap text-sm font-medium" : null}>
                              {t?.tollBillNo}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.tollAmount}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                              {t?.threePointDate ? new Date(t?.threePointDate).toLocaleDateString("en-GB") : "-"}
                            </td>
                            <td className={t?.threePointBill ? "text-green-700 bg-green-100 px-6 py-4 whitespace-nowrap text-sm font-medium" : null}>
                              {t?.threePointBill}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {t?.threePointAmount}
                            </td>


                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                              {t?.incentiveAmount}
                            </td>



                            {user?.role === "Admin" ? <td className="text-center">
                              <i onClick={() => deleteTrip(t._id)} className="fa-solid fa-trash text-red-600 hover:scale-150 transition-transform"></i>
                            </td>
                              :
                              null}

                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          {!paginationOn ? (
            <></>
          ) : (
            <Pagination
              color="warning"
              showControls
              total={totalPages}
              initialPage={page}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div >
    </>
  );
};

export default AllRecords;
