import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify"
import * as XLSX from 'xlsx';
import {
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import Loader from "../../components/Loader";

const AllExpenses = () => {
  const [expenses, setexpenses] = useState([]);
  const [displayExpenses, setDisplayExpenses] = useState([]);
  const [uichange, setuichange] = useState(false);


  const [startDate, setStartDate] = useState("");
  const [userName, setUserName] = useState("");


  const [loading, setloading] = useState(false);
  const [deleteId, setdeleteId] = useState()

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const getAllExpenses = async () => {
    try {
      setloading(true);
      const response = await axios.get("/expenses/get/all")
      setloading(false);
      if (response.data.success) {
        setexpenses(response.data.expenses);
        setDisplayExpenses(response.data.expenses);
      }
    } catch (error) {
      setloading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  }


  useEffect(() => {
    getAllExpenses();

  }, [uichange]);

  //total price calculations....
  const [totalPrice, settotalPrice] = useState();
  useEffect(() => {
    if (displayExpenses.length > 0) {
      let totalPrice = 0;
      displayExpenses?.map((e) => (totalPrice = totalPrice + e.amount));
      settotalPrice(totalPrice);
    } else {
      settotalPrice(0)
    }
  }, [displayExpenses]);

  const deleteExpense = async (id) => {

    try {
      const response = await axios.delete(`/expenses/${id}`);

      if (response.data.success) {
        toast.success(response.data.message);
        setuichange(!uichange);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleChange = (e) => {
    const name = e.target.value.toLowerCase();
    setUserName(name);
    if (name === "" && startDate === "") {
      setDisplayExpenses(expenses);
    } else if (name !== "" && startDate === "") {
      setDisplayExpenses(
        expenses.filter((e) => {
          return e.user?.name.toLowerCase().includes(name);
        })
      )
    } else if (name !== "" && startDate !== "") {
      setDisplayExpenses(
        displayExpenses.filter((e) => {
          return e.user?.name.toLowerCase().includes(name);
        })
      )
    } else if (name === "" && startDate !== "") {
      setDisplayExpenses(
        expenses.filter((e) => {
          return e.date.includes(startDate);
        })
      )
    }
  }

  //fun for getting expenses in range
  const getExpensesInRange = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    try {
      const response = await axios.get(`/expenses/get/expensesByRange?startDate=${data.startDate}&endDate=${data.endDate}`);
      if (response.data.success) {
        setDisplayExpenses(response.data.expenses);
      }

    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  // const handleDateChange = (e) => {
  //   const date = e.target.value;
  //   setStartDate(date);
  //   if (date === '' && userName === "") {
  //     setDisplayExpenses(expenses);
  //   } else if (date !== "" && userName === "") {
  //     setDisplayExpenses(
  //       expenses.filter((e) => {
  //         return e.date.includes(date);
  //       })
  //     )
  //   } else if (date !== "" && userName !== "") {
  //     setDisplayExpenses(
  //       displayExpenses.filter((e) => {
  //         return e.date.includes(date);
  //       })
  //     )
  //   } else if (date === "" && userName !== "") {
  //     setDisplayExpenses(
  //       expenses.filter((e) => {
  //         return e.user?.name.toLowerCase().includes(userName);
  //       })
  //     )
  //   }
  // }

  const downloadSheet = () => {
    const arr = [];
    let total = 0;
    displayExpenses.forEach(expenses => {
      let date = expenses.date;
      date = new Date(date).toLocaleDateString('en-GB');
      const sheet = {
        ...expenses,
        name: expenses.user?.name,
        date
      }
      delete (sheet.user);
      delete (sheet._id);
      delete (sheet.__v);
      delete (sheet.createdAt);
      delete (sheet.updatedAt);
      arr.push(sheet);
      total += sheet.amount;
    });

    const obj = {
      date: "Total Amount",
      amount: total
    }

    arr.push(obj);


    const workSheet = XLSX.utils.json_to_sheet(arr);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
    XLSX.writeFile(workBook, 'expenses.xlsx');
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <div className="px-5">
        <h1 className="text-center my-2 underline font-bold italic">
          Expenses
        </h1>
        <form onSubmit={getExpensesInRange} className="absolute top-20 left-8 flex items-center gap-2">
          <Input label={"start date"} type="date" className="max-w-sm" name="startDate" />
          <Input label={"end date"} type="date" className="max-w-sm" name="endDate" />
          <Button color="warning" type="submit" className="w-[200px] text-white">Get Expenses</Button>
        </form>
        <div className="absolute top-20 right-8 ">
          <Input className="w-[20vw] font-bold" type="Search" label="Search by User" onChange={handleChange} />
        </div>
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              {expenses.length > 0 ? (
                <div className="flex items-baseline w-full justify-between">
                  <p className="ml-5 mt-5">
                    <span className="font-bold">Total Amount:</span>{" "}
                    <span className="text-green-700 font-bold">
                      ₹ {totalPrice.toLocaleString("en-IN")}
                    </span>
                  </p>
                  <Button onClick={downloadSheet} color="warning" className="m-6 text-white">Download</Button>
                </div>
              ) : null}
              <div className="border rounded-lg overflow-hidden mt-5 shadow">
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        Mode of Payment
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        Reason
                      </th>

                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        User
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {displayExpenses?.map((e, index) => {
                      return (
                        <>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {new Date(e?.date).toLocaleDateString("en-GB")}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              {e?.amount}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              {e?.modeOfPayment}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              {e?.reason}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              {e?.user?.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              <Button onPress={() => {
                                setdeleteId(e._id)
                                onOpen()
                              }} color="primary">
                                <i class="fa-solid fa-trash"></i>
                              </Button>
                            </td>
                            <div>
                              <Modal
                                isOpen={isOpen}
                                onOpenChange={onOpenChange}
                              >
                                <ModalContent>
                                  {(onClose) => (
                                    <>
                                      <ModalHeader className="flex flex-col gap-1">
                                        Delete Confirmation
                                      </ModalHeader>
                                      <ModalBody>
                                        <p>
                                          Do you want to delete this record?
                                        </p>
                                      </ModalBody>
                                      <ModalFooter>
                                        <Button
                                          color="danger"
                                          variant="light"
                                          onPress={onClose}
                                        >
                                          No
                                        </Button>
                                        <Button
                                          onClick={() => deleteExpense(deleteId)}
                                          color="primary"
                                          onPress={onClose}
                                        >
                                          Yes
                                        </Button>
                                      </ModalFooter>
                                    </>
                                  )}
                                </ModalContent>
                              </Modal>
                            </div>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default AllExpenses;
