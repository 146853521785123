import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

const ProtectedRoute = ({ children }) => {

    const navigate = useNavigate();
    const { token } = useSelector(state => state.user);

    useEffect(() => {
        if (!token) {
            toast.error("Unauthoroized acesss!");
            navigate("/");
        }
    }, [])

    return children;
}

export default ProtectedRoute;