/**
 * 
 * @param {*} size length of the otp
 * @returns String
 */
const generateOtp = (size) => {
    const digits = "0123456789";
    let otp = "";
    for (let i = 0; i < size; i++) {
        let num = Math.round(Math.random() * (digits.length - 1));
        otp = otp + digits[num];
    }
    return otp;
};

export { generateOtp }