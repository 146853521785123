import { Button, Input } from '@nextui-org/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { useSelector } from 'react-redux';

const UpdateTrip = () => {
    const navigate = useNavigate();
    const [gpiNo, setgpiNo] = useState();

    const { user } = useSelector(state => state.user);

    const { id } = useParams();

    const [loading, setloading] = useState(true);

    const [tripData, setTripData] = useState();


    useEffect(() => {
        (async () => {
            try {
                setloading(true);
                const response = await axios.get(`/trip/${id}`);
                setloading(false);
                if (response.data.success) {
                    setTripData(response.data.tripData);
                }
            } catch (error) {
                toast.error(error.response.data.message);
            }
        })();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const formdata = new FormData(e.target);
            const data = Object.fromEntries(formdata.entries());
            // console.log(data);

            let filteredData = {}
            for (let key in data) {
                if (data[key]) {
                    filteredData = { ...filteredData, [key]: data[key] }
                }
            }

            if (Object.keys(filteredData).length === 0) {
                toast.error("Please provide a field!");
                return;
            }

            try {
                const response = await axios.put(`/trip/updateTrip/${tripData._id}`, filteredData);
                if (response.data.success) {
                    toast.success(response.data.message);
                    navigate("/all-records");
                }
            } catch (error) {
                toast.error(error.response.data.message);
            }

        } catch (error) {

            toast.error(error.response.data.message);
        }
    }

    const star = <span className="text-red-600 font-bold">*</span>;

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <div className="flex justify-center items-center">
                <form
                    onSubmit={handleSubmit}
                    className="flex flex-col w-[90vw] sm:w-[60vw]  lg:w-[50vw] justify-center items-center border-2 p-10 pb-2 my-5 gap-6 rounded-lg">

                    <h1 className="underline font-bold">Update Trip</h1>

                    <div className="flex gap-4 w-full">
                        <div className='w-3/5'>
                            <label htmlFor="date-time" className="text-sm pl-3">Date-time {star}</label>
                            <Input type="text" variant='underlined' id="date-time" name="tripDate"
                                placeholder={new Date(tripData?.tripDate).toLocaleDateString() + " " + new Date(tripData?.tripDate).toLocaleTimeString()} isDisabled />
                        </div>
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            name="gpiNo"
                            isRequired
                            required
                            type="number"
                            variant="underlined"
                            label={`GPI No`}
                            placeholder={tripData?.gpiNo}
                            isDisabled
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            name="ton"
                            isRequired
                            required
                            type="number"
                            variant="underlined"
                            label="Ton"
                            placeholder={tripData?.ton}
                            isDisabled
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            name="lrNo"
                            isRequired
                            required
                            type="number"
                            variant="underlined"
                            label="LR No"
                            placeholder={tripData?.lrNo}
                            isDisabled
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className="w-3/5"
                            name="destination"
                            isRequired
                            required
                            type="text"
                            variant="underlined"
                            label="Destination"
                            placeholder={tripData?.destination}
                            isDisabled
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            name="truckNo"
                            isRequired
                            required
                            type="text"
                            variant="underlined"
                            label="Truck No"
                            placeholder={tripData?.truckNo}
                            isDisabled
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className=""
                            name="threePointAmount"
                            type="number"
                            variant="underlined"
                            label="3 point Amount"
                            placeholder={tripData?.threePointAmount}
                            isDisabled={(tripData?.threePointAmount && user?.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className=""
                            name="threePointBill"
                            type="text"
                            variant="underlined"
                            label="3 point Bill"
                            placeholder={tripData?.threePointBill}
                            isDisabled={(tripData?.threePointBill && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className=""
                            name="threePointDate"
                            type="date"
                            variant="underlined"
                            label="3 point Date"
                            placeholder={new Date(tripData?.threePointDate).toLocaleDateString()}
                            isDisabled={(tripData?.threePointDate && user.role !== "Admin") ? true : false}
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            name="companyRate"
                            type="number"
                            variant="underlined"
                            label="Company Rate"
                            placeholder={" " + tripData?.companyRate}
                            isDisabled={(tripData?.companyRate && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            name="lorryRate"
                            type="number"
                            variant="underlined"
                            label="Lorry Rate"
                            placeholder={" " + tripData?.lorryRate}
                            isDisabled={(tripData?.lorryRate && user.role !== "Admin") ? true : false}
                        />
                        {/* <span className="w-[1px] h-10 mt-4 bg-black"></span> */}
                        {/* <Input
              name="totalAmount"
              type="number"
              variant="underlined"
              label="Total Amount"
              
            /> */}
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className="w-3/5"
                            name="soldToParty"
                            type="text"
                            variant="underlined"
                            label="Sold To Party"
                            placeholder={tripData?.soldToParty}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            name="contactNo"
                            className="w-2/5"
                            type="number"
                            variant="underlined"
                            label="Contact No"
                            placeholder={tripData?.contactNo}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            name="lorryTotal"
                            className="w-2/5"
                            type="number"
                            variant="underlined"
                            label="Lorry Total"
                            placeholder={tripData?.lorryTotal}
                            isDisabled
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            name="advancedAmount"
                            type="number"
                            variant="underlined"
                            label="Advanced Amount"
                            placeholder={" " + tripData?.advancedAmount}
                            isDisabled={(tripData?.advancedAmount && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            name="advancedAmountDate"
                            type="date"
                            variant="underlined"
                            label="Advanced Amount Date"
                            placeholder={" " + tripData?.advancedAmountDate}
                            isDisabled={(tripData?.advancedAmountDate && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            name="dieselAmount"
                            type="number"
                            variant="underlined"
                            label="Diesel Amount"
                            placeholder={" " + tripData?.dieselAmount}
                            isDisabled={(tripData?.dieselAmount && user.role !== "Admin") ? true : false}
                        />

                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className="w-3/5"
                            name="billNo"
                            type="text"
                            variant="underlined"
                            label="Bill No"
                            placeholder={tripData?.billNo}
                            isDisabled={(tripData?.billNo && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            name="billDate"
                            className="w-2/5"
                            type="date"
                            variant="underlined"
                            label={tripData?.billDate ? "Bill Date: " + new Date(tripData?.billDate)?.toLocaleDateString("en-GB") : "Bill Date: "}
                            placeholder={new Date(tripData?.billDate).toLocaleDateString("en-GB")}
                            isDisabled={(tripData?.billDate && user.role !== "Admin") ? true : false}
                        />

                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            name="tollBillNo"
                            className="w-2/5"
                            type="text"
                            variant="underlined"
                            label={"Toll-Bill-No "}
                            placeholder={tripData?.tollBillNo}
                            isDisabled={(tripData?.tollBillNo && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className="w-3/5"
                            name="tollAmount"
                            type="text"
                            variant="underlined"
                            label="Toll Amount"
                            placeholder={" " + tripData?.tollAmount}
                            isDisabled={(tripData?.tollAmount && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className="w-3/5"
                            name="tollDate"
                            type="date"
                            variant="underlined"
                            label="Toll-bill Date"
                            placeholder={" " + tripData?.tollDate}
                            isDisabled={(tripData?.tollDate && user.role !== "Admin") ? true : false}
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className="w-3/5"
                            name="postPaidAmount"
                            type="number"
                            variant="underlined"
                            label="Post Paid Amount"
                            placeholder={tripData?.postPaidAmount}
                            isDisabled={!(tripData?.lorryRate) ? true : (tripData?.postPaidAmount && user?.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className="w-3/5"
                            name="postPaidDate"
                            type="date"
                            variant="underlined"
                            label="Post Paid Date"
                            placeholder={tripData?.postPaidDate}
                            isDisabled={(tripData?.postPaidDate && user.role !== "Admin") ? true : false}
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className="w-3/5"
                            name="postPaid2Amount"
                            type="number"
                            variant="underlined"
                            label="Post Paid 2 Amount"
                            placeholder={tripData?.postPaid2Amount}
                            isDisabled={!(tripData?.lorryRate) ? true :
                                (tripData.postPaid2Amount && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className="w-3/5"
                            name="postPaid2Date"
                            type="date"
                            variant="underlined"
                            label="Post Paid 2 Date "
                            placeholder={tripData?.postPaid2Date}
                            isDisabled={(tripData?.postPaid2Date && user.role !== "Admin") ? true : false}
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className="w-3/5"
                            name="postPaid3Amount"
                            type="number"
                            variant="underlined"
                            label="Post Paid 3 Amount"
                            placeholder={tripData?.postPaid3Amount}
                            isDisabled={!(tripData?.lorryRate) ? true :
                                (tripData.postPaid3Amount && user.role !== "Admin") ? true : false
                            }
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className="w-3/5"
                            name="postPaid3Date"
                            type="date"
                            variant="underlined"
                            label="Post Paid 3 Date "
                            placeholder={tripData?.postPaid3Date}
                            isDisabled={(tripData?.postPaid3Date && user.role !== "Admin") ? true : false}
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className="w-3/5"
                            name="extraAmount"
                            type="number"
                            variant="underlined"
                            label="Extra Amount"
                            placeholder={tripData?.extraAmount}
                            isDisabled={(tripData?.extraAmount && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className="w-3/5"
                            name="extraAmountRemark"
                            type="text"
                            variant="underlined"
                            label="Extra amount remark"
                            placeholder={tripData?.extraAmountRemark}
                            isDisabled={(tripData?.extraAmountRemark && user.role !== "Admin") ? true : false}
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className=""
                            name="unloading"
                            type="number"
                            variant="underlined"
                            label="Unloading"
                            placeholder={tripData?.unloading}
                            isDisabled={(tripData?.unloading && user.role !== "Admin") ? true : false}
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <Input
                            className=""
                            name="incentiveAmount"
                            type="number"
                            variant="underlined"
                            label="Incentive Amount"
                            placeholder={tripData?.incentiveAmount}
                            isDisabled={(tripData?.incentiveAmount && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className=""
                            name="advanceAmountRemark"
                            type="text"
                            variant="underlined"
                            label="Advance Amount Remark"
                            placeholder={tripData?.advanceAmountRemark}
                            isDisabled={(tripData?.advanceAmountRemark && user.role !== "Admin") ? true : false}
                        />
                        <span className="w-[1px] h-10 mt-4 bg-black"></span>
                        <Input
                            className=""
                            name="postPaidAmountRemark"
                            type="text"
                            variant="underlined"
                            label="Post-Paid Amount Remark"
                            placeholder={tripData?.postPaidAmountRemark}
                            isDisabled={(tripData?.postPaidAmountRemark && user.role !== "Admin") ? true : false}
                        />
                    </div>

                    <Button
                        type="submit"
                        color="primary"
                        className="m-4 w-2/5 rounded-md">
                        Submit
                    </Button>

                </form>
            </div >

        </>
    )
}

export default UpdateTrip;