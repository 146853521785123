import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import Loader from "../../components/Loader";

const AllUsers = () => {

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [loading, setloading] = useState(false);

  const navigate = useNavigate()
  const [users, setUsers] = useState();
  const [displayUsers, setDisplayUsers] = useState();
  const [uichange, setuichange] = useState(false);

  const [deleteId, setdeleteId] = useState();

  const { user } = useSelector(state => state.user);

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        setloading(true);
        const users = await axios.get("/user/");
        setloading(false);

        if (users?.data.success) {
          setUsers(users?.data.user);
          setDisplayUsers(users?.data.user);
        }
      } catch (error) {
        setloading(false);
        toast.error(error.response.data.message);
      }
    };

    getAllUsers();
  }, [uichange]);

  const deleteUser = async (id) => {

    try {

      const response = await axios.delete(`/user/${id}`);

      if (response.data.success) {
        setuichange(!uichange);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleChange = (e) => {
    const name = e.target.value.toLowerCase();

    if (name === "") {
      setDisplayUsers(users);

    } else {
      
      setDisplayUsers(
        users.filter((user) => {
          return user.name.toLowerCase().includes(name);
        })
      )
    }

  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <div className="px-5">
        <h1 className="text-center my-2 underline font-bold italic">Users</h1>
        <div className="absolute top-20 right-8 ">
          <Input className="w-[20vw] font-bold" type="Search" label="Search" onChange={handleChange} />
        </div>
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="border rounded-lg overflow-hidden mt-5 shadow">
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {displayUsers?.map((e, index) => {
                      return (
                        <>
                          <tr className={user._id === e._id ? "bg-green-200" : null}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                              {e?.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              {e?.email}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              {e?.role}
                            </td>
                            <td className={user._id === e._id ? "hidden" : null}>
                              <i
                                onClick={() => navigate(`/updateUser/${e._id}`)}
                                class="cursor-pointer mr-4 ml-4 text-lg fa-solid fa-pen-to-square"
                              ></i>
                              <i
                                onClick={
                                  () => {
                                    setdeleteId(e._id)
                                    onOpen()
                                  }

                                }
                                class="cursor-pointer mr-4 text-lg fa-solid fa-trash"
                                style={{ color: "#f91a1a" }}
                              ></i>
                            </td>
                            <div className="bg-black">
                              <Modal
                                isOpen={isOpen}
                                onOpenChange={onOpenChange}
                              >
                                <ModalContent>
                                  {(onClose) => (
                                    <>
                                      <ModalHeader className="flex flex-col gap-1">
                                        Delete Confirmation
                                      </ModalHeader>
                                      <ModalBody>
                                        <p>
                                          Do you want to delete this record?
                                        </p>
                                      </ModalBody>
                                      <ModalFooter>
                                        <Button
                                          color="danger"
                                          variant="light"
                                          onPress={onClose}
                                        >
                                          No
                                        </Button>
                                        <Button
                                          onClick={() => deleteUser(deleteId)}
                                          color="primary"
                                          onPress={onClose}
                                        >
                                          Yes
                                        </Button>
                                      </ModalFooter>
                                    </>
                                  )}
                                </ModalContent>
                              </Modal>
                            </div>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllUsers;
