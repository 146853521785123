import React, { useEffect, useState } from "react";
import { Input, Button } from "@nextui-org/react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { unVerifyOtp } from "../redux/userSlice";
const ResetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const verifiedOtp = useSelector(state => state.user.otpVerified);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!verifiedOtp) {
      toast.error("Cannot access the resources!")
      navigate('/userVerification');
    }
  }, [])

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);
  const toggleVisibility2 = () => setIsVisible2(!isVisible2);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    let data = Object.fromEntries(formData.entries());

    data = {
      ...data,
      email: params.email
    }
    if (data.password === data.confirmPassword) {
      console.log(data);
      try {
        const response = await axios.patch(`/user/resetPassword`, data);

        if (response.data.success) {
          toast.success(response.data.message);
          navigate('/');
        }
      } catch (error) {
        console.log(error.response.data.message);
        navigate('/');
      } finally {
        dispatch(unVerifyOtp());
      }

    } else {
      toast.error('password not match');
    }

  };

  return (
    <>
      <div className="flex justify-center items-center h-screen ">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-1/4 border-2 p-10 bg-gray-100 rounded-lg justify-center items-center"
        >
          <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Input
              required
              label="Set Password"
              name="password"
              variant="underlined"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                  aria-label="toggle password visibility"
                >
                  {isVisible ? (
                    <i class="fa-solid fa-eye"></i>
                  ) : (
                    <i class="fa-solid fa-eye-slash"></i>
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
            />
          </div>

          <div className="flex w-4/5 flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Input
              required
              label="Confirm Password"
              name="confirmPassword"
              variant="underlined"
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility2}
                  aria-label="toggle password visibility"
                >
                  {isVisible2 ? (
                    <i class="fa-solid fa-eye"></i>
                  ) : (
                    <i class="fa-solid fa-eye-slash"></i>
                  )}
                </button>
              }
              type={isVisible2 ? "text" : "password"}
            />
          </div>

          <Button
            type="submit"
            color="primary"
            className=" mt-8 w-2/5 rounded-md"
          >
            Reset Password
          </Button>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
